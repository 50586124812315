<template>
  <a-modal
    :visible="value"
    title="复制播主分组"
    :mask-closable="false"
    @cancel="handleCancel"
  >
    <a-form-model
      class="form"
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ flex: '0 0 110px' }"
      :wrapper-col="{ flex: '0 0 calc(100% - 110px)'}"
    >
      <a-form-model-item class="mb-0" label="待复制播主分组" prop="groupName">
        {{ form.groupName || '-' }}
      </a-form-model-item>
      <a-form-model-item class="mb-0" label="包含播主号" prop="groupAuthorCount">
        {{ form.groupAuthorCount || '-' }}
      </a-form-model-item>
      <a-form-model-item class="mb-0" label="新分组类型" prop="newGroupType">
        <template v-if="form.newGroupType === 1">公共分组</template>
        <template v-else-if="form.newGroupType === 2">私有分组</template>
        <template v-else>-</template>
      </a-form-model-item>
      <a-form-model-item label="分组名称" prop="newGroupName">
        <a-input
          v-model.trim="form.newGroupName"
          allow-clear
          placeholder="请输入分组名称"
        />
      </a-form-model-item>
    </a-form-model>
    <template slot="footer">
      <div class="btn-wrap">
        <div class="btn-wrap__left">
          <a>提示：你可以在复制分组后再调整分组內的播主号。</a>
        </div>
        <div class="btn-wrap__right">
          <a-button @click="handleCancel">
            取消
          </a-button>
          <a-button type="primary" :loading="confirmLoading" @click="handleConfirm">
            确定
          </a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'CopyAuthorGroupModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default() {
        return {
          groupId: '',
          groupName: '',
          groupType: '',
          groupCode: '',
          groupAuthorCount: '',
          groupAuthorList: [],
          newGroupType: '',
          newGroupName: ''
        }
      }
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: {
        newGroupName: [
          { required: true, message: '请输入分组名称', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('confirm', { ...this.form })
        } else {
          return false
        }
      }) 
    },
    handleCancel() {
      this.$emit('input', false)
      this.$emit('cancel')
      this.resetForm()
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.form.groupId = ''
      this.form.groupName = ''
      this.form.groupType = ''
      this.form.groupCode = ''
      this.form.groupAuthorCount = ''
      this.form.groupAuthorList = []
      this.form.newGroupType = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  display: flex;
}

.mb-0 {
  margin-bottom: 0;
}

.btn-wrap {
  display: flex;
  align-items: center;

  &__left {
    flex: 1;
    margin-right: 8px;
    text-align: left;
  }
}
</style>
