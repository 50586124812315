<template>
  <div class="data-table" ref="dataTable">
    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="data"
      :row-key="(record, index) => index"
      :pagination="false"
      :scroll="scroll"
    >
      <div class="operate" slot="operate" slot-scope="text, record">
        <router-link
          :to="{ name: 'authorGroupDetail', params: { groupId: record.id } }"
          target="_blank"
        >
          {{ record.isCreator === 1 ? '查看编辑' : '查看详情' }}
        </router-link>

        <template v-if="record.isCreator !== 1">
          <a-divider type="vertical" />
          <a v-if="record.is_follow === 1" @click="handleOperate('unfollow', record)">取消关注</a>
          <a v-else @click="handleOperate('follow', record)">关注</a>
        </template>

        <a-divider type="vertical" />
        <a @click="handleOperate('copy', record)">复制</a>

        <template v-if="record.isCreator === 1">
          <a-divider type="vertical" />
          <a @click="handleOperate('delete', record)">删除</a>
        </template>
      </div>
    </a-table>

    <BasePagination
      ref="basePagination"
      :currentPage="pagination.page"
      :pageSize="pagination.size"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </div>
</template>

<script>
import formatUtil from '@/utils/FormatUtil'

const columns = [
  {
    title: '播主分组',
    dataIndex: 'name',
    width: 260
  },
  {
    title: '分组编号',
    dataIndex: 'group_code',
    width: 180
  },
  {
    title: '包含帐号',
    dataIndex: 'dy_account_total',
    width: 120,
    customRender: (text, record, index) => {
      return formatUtil.humanReadable(record.dy_account_total + record.ks_account_total)
    }
  },
  {
    title: '包含经销商',
    dataIndex: 'dealer_total',
    width: 120,
    customRender: (text, record, index) => {
      return formatUtil.humanReadable(record.dealer_total)
    }
  },
  {
    title: '同步经销商分组',
    dataIndex: 'sync_dealer_common_group_name',
    width: 180,
    customRender: (text, record, index) => {
      return record.sync_dealer_common_group_name || '不同步'
    }
  },
  {
    title: '创建人',
    dataIndex: 'user_oauth_name',
    width: 160,
    customRender: (text, record, index) => {
      return record.user_oauth_name || '-'
    }
  },
  {
    title: '更新时间',
    dataIndex: 'mtime',
    width: 180
  },
  {
    title: '创建时间',
    dataIndex: 'ctime',
    width: 180
  },
  {
    title: '操作',
    key: 'operate',
    width: 212,
    fixed: 'right',
    scopedSlots: { customRender: 'operate' }
  }
]

export default {
  name: 'DataTable',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default() {
        return []
      }
    },
    pagination: {
      type: Object,
      default() {
        return {
          page: 1,
          size: 10,
          total: 0
        }
      }
    }
  },
  data() {
    return {
      columns,
      scroll: {}
    }
  },
  watch: {
    data() {
      this.$nextTick(() => {
        this.setTableScroll()
      })
    }
  },
  methods: {
    handlePaginationChange(page, size) {
      this.$emit('pagination-change', page, size)
    },
    handleOperate(type, record) {
      this.$emit('operate', type, record)
    },
    setTableScroll() {
      const winHeight = document.documentElement.offsetHeight || document.body.offsetHeight
      const width = this.$refs.dataTable.offsetWidth
      const height = this.$refs.dataTable.offsetHeight
      const columnsWidth = this.columns.reduce((total, currVal) => {
        return total + currVal.width;
      }, 0)
      const scroll = {}

      if (width - 48 < columnsWidth) {
        scroll.x = columnsWidth
      }

      if (height + 142 > winHeight) {
        const actionBarHeight = this.$refs.actionBar ? this.$refs.actionBar.$el.offsetHeight : 0
        const tableHeadHeight = document.querySelector('.ant-table-thead').offsetHeight
        const otherHeight  = actionBarHeight + tableHeadHeight + 190

        scroll.y = `calc(100vh - ${otherHeight}px)`
      }

      this.scroll = scroll
    }
  },
  mounted() {
    this.setTableScroll()
     this.$_resizeHandler = () => {
      this.setTableScroll()
    }
  }
}
</script>

<style lang="scss" scoped>
.data-table {
  padding: 24px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
}
</style>
