<template>
  <div class="search-filter">
    <a-form-model
      class="form"
      ref="form"
      :model="form"
      :label-col="{ flex: '0 0 100px' }"
      :wrapper-col="{ flex: '0 0 calc(100% - 100px)' }"
    >
      <a-row :gutter="16">
        <a-col :span="colSpan1">
          <a-form-model-item label="分组名称" prop="name">
            <a-input v-model.trim="form.name" allow-clear placeholder="请输入分组名称" />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan1">
          <a-form-model-item label="包含播主" prop="searchText">
            <a-input v-model.trim="form.searchText" allow-clear placeholder="请输入播主昵称/经销商名称/编码/公司名" />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan1">
          <a-form-model-item label="创建人" prop="creatorId">
            <a-select
              v-model="form.creatorId"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              show-search
              style="width: 100%"
              @search="handleCreatorSearch"
            >
              <a-select-option v-for="item in creatorList" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan1">
          <a-form-model-item label="是否同步经销商公共分组" prop="isSync">
            <a-select
              v-model="form.isSync"
              :default-active-first-option="false"
              style="width: 100%"
              @change="handleIsSyncChange"
            >
              <a-select-option v-for="item in syncList" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan1">
          <a-form-model-item label="同步经销商公共分组" prop="syncDealerGroupName">
            <a-input
              v-model.trim="form.syncDealerGroupName"
              :disabled="form.isSync === 0"
              allow-clear
              placeholder="请输入同步经销商公共分组"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan2">
          <a-form-model-item :wrapper-col="{ flex: '1 0 auto' }">
            <div class="btn-wrap">
              <a-button class="mr-8" @click="handleReset"> 重置 </a-button>
              <a-button type="primary" :loading="loading" @click="handleSearch"> 查询 </a-button>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import resizeMixin from '@/mixins/dataTableMixin';

const syncList = [
  { label: '全部', value: '' },
  { label: '是', value: 1 }, //,
  // { label: '否', value: 0 }
];

export default {
  name: 'SearchFilter',
  mixins: [resizeMixin],
  props: {
    form: {
      type: Object,
      default() {
        return {
          name: '',
          searchText: '',
          creatorId: '',
          isSync: '',
          syncDealerGroupName: '',
        };
      },
    },
    fetchCreator: {
      type: Function,
      default() {
        return () => {};
      },
    },
    creatorList: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    this.$_resizeHandler = () => {
      this.setColSpan();
    };

    return {
      syncList,
      colSpan1: 12,
      colSpan2: 24,
    };
  },
  created() {
    this.setColSpan();
  },
  methods: {
    handleCreatorSearch(searchText) {
      this.fetchCreator(searchText);
    },
    handleIsSyncChange() {
      if (this.form.isSync === 0) {
        this.form.syncDealerGroupName = '';
      }
    },
    setColSpan() {
      const width = document.documentElement.offsetWidth;

      if (width < 1200) {
        this.colSpan1 = 12;
        this.colSpan2 = 12;
      } else if (width >= 1200 && width < 1600) {
        this.colSpan1 = 8;
        this.colSpan2 = 8;
      } else if (width >= 1600) {
        this.colSpan1 = 6;
        this.colSpan2 = 18;
      }
    },
    handleReset() {
      this.$refs.form.resetFields();
      this.fetchCreator('');
      this.$emit('search', { ...this.form });
    },
    handleSearch() {
      this.$emit('search', { ...this.form });
    },
  },
};
</script>

<style lang="less" scoped>
.search-filter {
  padding: 24px 24px 16px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;

  .ant-form-item {
    display: flex;
    margin-bottom: 8px;

    /deep/ .ant-form-item-label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      line-height: 20px;
      white-space: pre-wrap;
    }
  }

  .btn-wrap {
    text-align: right;
  }

  .mr-8 {
    margin-right: 8px;
  }
}
</style>
