<template>
  <section class="page">
    <SearchFilter
      class="mb-16"
      :form="searchForm"
      :fetch-creator="fetchCreator"
      :creator-list="creatorList"
      :loading="loading"
      @search="handleSearch"
    />

    <DataTable
      :loading="loading"
      :data="authorPublicGroupList"
      :pagination="pagination"
      @pagination-change="handlePaginationChange"
      @operate="handleOperate"
    />

    <CopyAuthorGroupModal
      ref="copyAuthorGroupModal"
      v-model="copyAuthorGroupVisible"
      :form="copyAuthorGroupForm"
      :confirm-loading="copyAuthorGroupLoading"
      @confirm="handleCopyAuthorGroupConfirm"
    />
  </section>
</template>

<script>
import SearchFilter from './components/SearchFilter'
import DataTable from './components/DataTable'
import CopyAuthorGroupModal from '@/components/CopyAuthorGroupModal'
import authorApi from '@/api/author'

export default {
  name: 'AuthorGroupList',
  components: {
    SearchFilter,
    DataTable,
    CopyAuthorGroupModal 
  },
  data() {
    return {
      searchForm: {
        name: '',
        searchText: '',
        creatorId: '',
        isSync: '',
        syncDealerGroupName: ''
      },
      creatorList: [
        { label: '全部', value: '' }
      ],
      loading: false,
      authorPublicGroupList: [],
      pagination: {
        page: 1,
        size: 10,
        total: 10
      },
      copyAuthorGroupVisible: false,
      copyAuthorGroupForm: {
        groupId: '',
        groupName: '',
        groupType: '',
        groupCode: '',
        groupAuthorCount: '',
        groupAuthorList: [],
        newGroupType: '',
        newGroupName: ''
      },
      copyAuthorGroupLoading: false
    }
  },
  mounted() {
    const { nickname, dealerGroupName } = this.$route.query

    nickname && (this.searchForm.searchText = nickname)

    if (dealerGroupName) {
      this.searchForm.isSync = 1
      this.searchForm.syncDealerGroupName = dealerGroupName
    }

    this.fetchAuthorPublicGroupList()
  },
  methods: {
    // 获取创建人列表
    async fetchCreator(searchText) {
      if (!searchText) {
        this.creatorList = [
          { label: '全部', value: '' }
        ]
        return false  
      }

      try {
        const params = {
          creatorName: searchText
        }
        const { code, data, message } = await authorApi.fetchAuthorGroupCreatorList(params)

        if (code === 200) {
          this.creatorList = data.map(item => {
            return { label: item.userOauthName, value: item.creatorId }
          })
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
      }
    },
    handleSearch() {
      this.pagination.page = 1
      this.fetchAuthorPublicGroupList()
    },
    handlePaginationChange(page, size) {
      this.pagination.page = page
      this.pagination.size = size
      this.fetchAuthorPublicGroupList()
    },
    // 获取经销商公共分组列表
    async fetchAuthorPublicGroupList() {
      this.loading = true
      try {
        const { page, size } = this.pagination
        const params = {
          ...this.searchForm,
          page,
          size
        }
        const { code, data, message } = await authorApi.fetchAuthorPublicGroupList(params)

        this.loading = false

        if (code === 200) {
          this.authorPublicGroupList = (data?.list || []).map(item => {
            return {
              ...item,
              isCreator: item.creator_id === this.$router.app.user.id ? 1 : 0
            }
          })
          this.pagination.total = data?.total || 0
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    handleOperate(type, record) {
      type === 'follow' && this.followAuthorGroup(record)
      type === 'unfollow' && this.unfollowAuthorGroup(record)
      type === 'copy' && this.openCopyAuthorGroupModal(record)
      type === 'delete' && this.$confirm({
        title: '该分组为公共分组，请谨慎删除！',
        okText: '确认删除',
        onOk: async () => {
          this.delAuthorGroup(record)
        }
      })
    },
    // 关注播主分组
    async followAuthorGroup(record) {
      try {
        const params = {
          groupId: record.id,
          groupName: record.name,
          groupType: 1
        }
        const { code, message } = await authorApi.followAuthorGroup(params)

        if (code === 200) {
          this.$message.success('关注播主分组成功')
          this.fetchAuthorPublicGroupList()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
      }
    },
    // 取消关注播主分组
    async unfollowAuthorGroup(record) {
      try {
        const params = {
          groupId: record.id,
          groupName: record.name,
          groupType: 1
        }
        const { code, message } = await authorApi.unfollowAuthorGroup(params)

        if (code === 200) {
          this.$message.success('取消关注播主分组成功')
          this.fetchAuthorPublicGroupList()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
      }
    },
    async openCopyAuthorGroupModal(record) {
      this.copyAuthorGroupVisible = true
      try {
        const params = {
          groupId: record.id,
          type: 1
        }
        const { code, data, message } = await authorApi.copySourceAutorGroup(params)
        
        if (code === 200) {
          this.copyAuthorGroupForm.groupId = data.id
          this.copyAuthorGroupForm.groupName = data.groupName
          this.copyAuthorGroupForm.groupType = data.isCommon
          this.copyAuthorGroupForm.groupCode = data.groupCode
          this.copyAuthorGroupForm.groupAuthorCount = data.authorAccountCount
          this.copyAuthorGroupForm.groupAuthorList = data.authorList
          this.copyAuthorGroupForm.newGroupType = data.newGroupType
          this.copyAuthorGroupForm.newGroupName = data.newGroupName
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
      }
    },
    // 处理复制播主分组确定
    async handleCopyAuthorGroupConfirm() {
      this.copyAuthorGroupLoading = true
      try {
        const { groupId, groupCode, newGroupType, newGroupName, groupAuthorList } = this.copyAuthorGroupForm
        const params = {
          groupList: [{
            groupId: groupId,
            groupCode: groupCode,
            groupType: newGroupType,
            groupName: newGroupName
          }],
          authorList: groupAuthorList
        }
        const { code, message } = await authorApi.copyAuthorGroup(params)

        this.copyAuthorGroupLoading = false

        if (code === 200) {
          this.$message.success('复制播主分组成功')
          this.copyAuthorGroupVisible = false
          this.$refs.copyAuthorGroupModal.resetForm()
          this.fetchAuthorPublicGroupList()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
        this.copyAuthorGroupLoading = false
      }
    }, 
    // 删除播主分组
    async delAuthorGroup(record) {
      try {
        const params = {
          groupId: record.id
        }
        const { code, message } = await authorApi.delAuthorGroup(params)

        if (code === 200) {
          this.$message.success('删除播主分组成功')
          this.fetchAuthorPublicGroupList()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.error(e)
      }  
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0 !important;
  background: #f0f2f5 !important;
}

.mb-16 {
  margin-bottom: 16px;
}
</style>
